<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<h3>Commission & Allocation History</h3>
		<BhLoading :show="loading" />
		<template>
			<div>
				<a-tabs default-active-key="units" size="large" v-model="typeTab">
					<a-tab-pane v-if="instance.productType === 'lowrise'" key="lots" tab="Lots"></a-tab-pane>
					<a-tab-pane v-if="instance.productType === 'highrise'" key="units" tab="Units"></a-tab-pane>
					<a-tab-pane v-if="instance.productType === 'highrise'" key="parking" tab="Parking Spots">
					</a-tab-pane>
					<a-tab-pane v-if="instance.productType === 'highrise'" key="lockers" tab="Lockers"> </a-tab-pane>
					<a-tab-pane v-if="instance.productType === 'highrise'" key="bikeRacks" tab="Bike Racks">
					</a-tab-pane>
				</a-tabs>
			</div>
			<div class="dF aC">
				<a-button :type="historyTab === 'commission' ? 'primary' : ''"
					@click="historyTab = 'commission'">Commission History</a-button>
				<a-button class="ml-4" :type="historyTab === 'allocation' ? 'primary' : ''"
					@click="historyTab = 'allocation'">Allocation History</a-button>
			</div>
			<div class="mt-3">
				<a-table :rowKey="(e) => e.id" class="white-table"
					:columns="historyTab === 'commission' ? commissionHistoryColumns : allocationHistoryColumns"
					:data-source="getData(typeTab, historyTab)" :scroll="{ x: 1000 }">
					<div slot="item" slot-scope="obj">
						{{ obj.itemName }}
					</div>
					<div slot="status" slot-scope="obj">
						<div>{{ salesStatus[obj.status] }}</div>
					</div>
					<div slot="date" slot-scope="obj" style="color: #9ea0a5">
						{{ formatDate(obj.createdAt) }}
					</div>
					<div slot="dateBefore" slot-scope="obj" style="color: #9ea0a5">
						{{ obj.dateBefore && formatDate(obj.dateBefore) || 'N/A' }}
					</div>
					<div slot="dateAfter" slot-scope="obj" style="color: #9ea0a5">
						{{ obj.dateAfter && formatDate(obj.dateAfter) || 'N/A' }}
					</div>
					<div slot="realtorBefore" slot-scope="obj">
						<span v-if="obj.realtorBefore">{{ obj.realtorBefore }}</span>
					</div>
					<div slot="realtorAfter" slot-scope="obj">
						<span v-if="obj.realtorAfter">{{ obj.realtorAfter }}</span>
					</div>
					<div slot="commissionBefore" slot-scope="obj">
						<span v-if="obj.commissionBefore">{{ obj.commissionBefore }}</span>
					</div>
					<div slot="commissionAfter" slot-scope="obj">
						<span v-if="obj.commissionAfter">{{ obj.commissionAfter }}</span>
					</div>
				</a-table>
			</div>
		</template>
	</div>
</template>

<script>
import BhLoading from 'bh-mod/components/common/Loading'
import { mapActions } from 'vuex';
import moment from "moment";
import { formatDate } from 'bh-mod';
export default {
	components: { BhLoading },
	data() {
		return {
			loading: false,
			salesStatus: {
				available:'Available',
				inventory:'Inventory',
				sold:'Sold',
				conditional:'Conditional',
				soldConditional:'Sold conditional',
				hold:'Hold',
				notreleased:'Not released',
				allocated: "Allocated",
				approved_for_reservation: "Approved",
				reserved: "Reserved"
			},
			commissionHistoryColumns: [
				{
					title: "Item #",
					key: "Item",
					scopedSlots: { customRender: "item" },
					sorter: (a, b) => {
						let aa = parseInt(a.itemName && a.itemName.toString().replace(/\D+/g, '') || 0) || 0;
						let bb = parseInt(b.itemName && b.itemName.toString().replace(/\D+/g, '') || 0) || 0;
						return aa - bb;
					},
					defaultSortOrder: 'ascend'
				},
				{
					title: "Status",
					key: "status",
					scopedSlots: { customRender: "status" },
					sorter: (a, b) =>
						a.status < b.status
							? -1
							: a.status > b.status
								? 1
								: 0,
				},
				{
					title: "Date",
					key: "date",
					scopedSlots: { customRender: "date" },
					sorter: (a, b) =>
						moment(a.createdAt).format("x") -
						moment(b.createdAt).format("x"),
				},
				{
					title: "Previous Commission",
					key: "Commission Before",
					scopedSlots: { customRender: "commissionBefore" },
					sorter: (a, b) => a.commissionBefore - b.commissionBefore,
				},
				{
					title: "New Commission",
					key: "New Commission",
					scopedSlots: { customRender: "commissionAfter" },
					sorter: (a, b) => a.commissionAfter - b.commissionAfter,
				}
			],
			allocationHistoryColumns: [
				{
					title: "Item #",
					key: "Item",
					scopedSlots: { customRender: "item" },
					sorter: (a, b) => {
						let aa = parseInt(a.itemName && a.itemName.toString().replace(/\D+/g, '') || 0) || 0;
						let bb = parseInt(b.itemName && b.itemName.toString().replace(/\D+/g, '') || 0) || 0;
						return aa - bb;
					},
					defaultSortOrder: 'ascend'
				},
				{
					title: "Status",
					key: "status",
					scopedSlots: { customRender: "status" },
					sorter: (a, b) =>
						a.status < b.status
							? -1
							: a.status > b.status
								? 1
								: 0,
				},
				{
					title: "Date",
					key: "date",
					scopedSlots: { customRender: "date" },
					sorter: (a, b) =>
						moment(a.createdAt).format("x") -
						moment(b.createdAt).format("x"),
				},
				{
					title: "Previous Realtor",
					key: "Realtor Before",
					scopedSlots: { customRender: "realtorBefore" },
					sorter: (a, b) =>
						a.realtorBefore < b.realtorBefore
							? -1
							: a.realtorBefore > b.realtorBefore
								? 1
								: 0,
				},
				{
					title: "New Realtor",
					key: "Realtor After",
					scopedSlots: { customRender: "realtorAfter" },
					sorter: (a, b) =>
						a.realtorAfter < b.realtorAfter
							? -1
							: a.realtorAfter > b.realtorAfter
								? 1
								: 0,
				},
				{
					title: "Previous Expiry Date",
					key: "dateBefore",
					scopedSlots: { customRender: "dateBefore" },
					sorter: (a, b) =>
						moment(a.dateBefore).format("x") -
						moment(b.dateBefore).format("x"),
				},
				{
					title: "New Expiry Date",
					key: "dateAfter",
					scopedSlots: { customRender: "dateAfter" },
					sorter: (a, b) =>
						moment(a.dateAfter).format("x") -
						moment(b.dateAfter).format("x"),
				},
			],
			typeTab: "units",
			historyTab: "commission",
			lotCommissionTimelines: [],
			lotAllocationTimelines: [],
			unitCommissionTimelines: [],
			unitAllocationTimelines: [],
			parkingCommissionTimelines: [],
			parkingAllocationTimelines: [],
			lockersCommissionTimelines: [],
			lockersAllocationTimelines: [],
			bikeRacksCommissionTimelines: [],
			bikeRacksAllocationTimelines: [],
			addOns: {
				parking: 'Parking Spot',
				lockers: 'Locker',
				bikeRacks: 'Bike Rack'
			},
			resizeObserver: null
		};
	},
	computed: {
		instance() {
			return this.$store.state.instance
		},

		dateFormat() {
			return this.$store.state.inventory.allSettings.user && this.$store.state.inventory.allSettings.user.options && this.$store.state.inventory.allSettings.user.options.regional && this.$store.state.inventory.allSettings.user.options.regional.dateFormat ? this.$store.state.inventory.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},

		storedLots() {
			const orderAlpha = (array) => {
				array = array.sort((a, b) => {
					if (parseInt(a.name) < parseInt(b.name))
						return -1;
					if (parseInt(a.name) > parseInt(b.name))
						return 1;
					else return 0;
				});
				return array;
			};
			return orderAlpha(
				Object.values(this.$store.state.inventory.lots)
			)
		},

		storedUnits() {
			const orderAlpha = (array) => {
				array = array.sort((a, b) => {
					if (parseInt(a.unitNumber) < parseInt(b.unitNumber))
						return -1;
					if (parseInt(a.unitNumber) > parseInt(b.unitNumber))
						return 1;
					else return 0;
				});
				return array;
			};
			return orderAlpha(
				Object.values(this.$store.state.inventory.units).filter(unit => unit.buildingType === "condounits")
			)
		},
		condoAddons() {
			return this.$store.state.inventory.condoAddons || { parking: [], lockers: [], bikeRacks: [] };
		},

		realtors() {
			return this.$store.state.realtors
		},
	},

	watch: {
		typeTab() {
			this.addUpdateScrollArrows()
		},
		historyTab() {
			this.addUpdateScrollArrows()
		}
	},

	methods: {
		moment, formatDate,
		...mapActions(['FETCH_REALTORS', 'FETCH_UPDATED_DATA', 'FETCH_ADD_ONS']),

		async getLotsHistory() {
			await this.FETCH_UPDATED_DATA();
			this.createHistoryData(this.storedLots, 'lots')
		},

		async getUnitsHistory() {
			await this.FETCH_UPDATED_DATA();
			this.createHistoryData(this.storedUnits, 'units')
		},

		async getAddOnsHistory() {
			await this.FETCH_ADD_ONS();
			this.createHistoryData(this.condoAddons, 'addOns')
		},

		createHistoryData(data, type = "units") {
			let commissionTimelinesKey, allocationTimelinesKey, itemNamePrefix, propertyName;

			if (type === "lots") {
				commissionTimelinesKey = 'lotCommissionTimelines';
				allocationTimelinesKey = 'lotAllocationTimelines';
				itemNamePrefix = 'Lot ';
				propertyName = 'name'
			} else if (type === "units") {
				commissionTimelinesKey = 'unitCommissionTimelines';
				allocationTimelinesKey = 'unitAllocationTimelines';
				itemNamePrefix = 'Unit ';
				propertyName = 'unitNumber'
			} else if (type === 'addOns') {
				propertyName = 'name'
				Object.entries(data).forEach(([addOn, addons]) => {
					const commissionTimelines = addOn + 'CommissionTimelines';
					const allocationTimelines = addOn + 'AllocationTimelines';
					this[commissionTimelines] = [];
					this[allocationTimelines] = [];

					addons.forEach(addon => {
						itemNamePrefix = this.addOns[addOn] + ' '
						this.processHistory(addon, commissionTimelines, allocationTimelines, itemNamePrefix, propertyName, type);
					});
				});
				return;
			}

			this[commissionTimelinesKey] = [];
			this[allocationTimelinesKey] = [];

			data.forEach(item => {
				this.processHistory(item, commissionTimelinesKey, allocationTimelinesKey, itemNamePrefix, propertyName, type);
			});
		},

		processHistory(item, commissionTimelinesKey, allocationTimelinesKey, itemNamePrefix, propertyName, type = 'units') {
			let itemName = itemNamePrefix + item[propertyName] + `${type == 'lots' && item['block'] ? ` (Block ${item['block']})` : ''}`
			if (item.commissionStructureHistory && item.commissionStructureHistory.length) {
				item.commissionStructureHistory.forEach((history, index) => {
					const obj = {
						id: item.id + index,
						itemName: itemName,
						status: type === "units" ? item.salesStatus : item.status,
						createdAt: history.date,
						commissionBefore: history.before && history.before.commissionAmount && ((history.before.commissionType === 'amount' ? '$' : '') + history.before.commissionAmount.toLocaleString() + (history.before.commissionType === 'percentage' ? '%' : '')),
						commissionAfter: history.after && history.after.commissionAmount && ((history.after.commissionType === 'amount' ? '$' : '') + history.after.commissionAmount.toLocaleString() + (history.after.commissionType === 'percentage' ? '%' : '')),
					};
					this[commissionTimelinesKey].push(obj);
				});
			}

			if (item.allocationHistory && item.allocationHistory.length) {
				item.allocationHistory.forEach((history, index) => {
					const obj = {
						id: item.id + index,
						itemName: itemName,
						status: type === "units" ? item.salesStatus : item.status,
						createdAt: history.date,
						realtorBefore: this.getRealtor(history.before && history.before.allocatedTo),
						realtorAfter: this.getRealtor(history.after && history.after.allocatedTo),
						dateBefore: history.before && history.before.allocationExpiresAt,
						dateAfter: history.after && history.after.allocationExpiresAt,
					};
					this[allocationTimelinesKey].push(obj);
				});
			}
		},

		getRealtor(id) {
			const user = this.realtors.find(r => r.id === id)
			if (user) {
				return user.firstName + " " + user.lastName
			}
			return 'N/A'
		},

		getData(typeTab, historyTab) {
			if (typeTab === 'lots') {
				if (historyTab === 'commission') {
					return this.lotCommissionTimelines;
				} else if (historyTab === 'allocation') {
					return this.lotAllocationTimelines;
				}
			} else if (typeTab === 'units') {
				if (historyTab === 'commission') {
					return this.unitCommissionTimelines;
				} else if (historyTab === 'allocation') {
					return this.unitAllocationTimelines;
				}
			} else {
				if (historyTab === 'commission') {
					return this[typeTab + 'CommissionTimelines'] // parkingCommissionTimelines, lockersCommissionTimelines, bikeRacksCommissionTimelines
				} else if (historyTab === 'allocation') {
					return this[typeTab + 'AllocationTimelines'] // parkingAllocationTimelines, lockersAllocationTimelines, bikeRacksAllocationTimelines
				}
			}
		},

		updateScrollVisibility() {
			let scrollContent = document.querySelector('div.ant-table-body');
			let scrollLeftButton = document.querySelector('.scroll-left')
			let scrollRightButton = document.querySelector('.scroll-right')

			if (scrollContent && scrollLeftButton && scrollRightButton) {
				if (scrollContent.offsetWidth < scrollContent.scrollWidth) {
					scrollLeftButton.classList.remove('hide');
					scrollRightButton.classList.remove('hide');
					scrollContent.classList.add('mx-3');
				} else {
					scrollLeftButton.classList.add('hide');
					scrollRightButton.classList.add('hide');
					scrollContent.classList.remove('mx-3');
				}
			}
		},

		addUpdateScrollArrows() {
			this.$nextTick(() => {
				const scrollableArea = document.querySelector('div.ant-table-body')

				let scrollLeftButton = document.createElement('div');
				let scrollRightButton = document.createElement('div');

				scrollLeftButton.className = 'scroll-button scroll-left';
				scrollLeftButton.innerHTML = '&lt;';

				scrollRightButton.className = 'scroll-button scroll-right';
				scrollRightButton.innerHTML = '&gt;';

				if (scrollableArea.offsetWidth < scrollableArea.scrollWidth) {
					if (!document.querySelector('.scroll-left')) {
						scrollableArea.appendChild(scrollLeftButton);
					}
					if (!document.querySelector('.scroll-right')) {
						scrollableArea.appendChild(scrollRightButton);
					}
					scrollableArea.classList.add('mx-3');
				} else {
					if (scrollableArea && document.querySelector('.scroll-left') && document.querySelector('.scroll-right')) {
						scrollLeftButton.classList.add('hide');
						scrollRightButton.classList.add('hide');
						scrollableArea.classList.remove('mx-3');
					}
				}

				scrollLeftButton.addEventListener('click', function () {
					const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
					scrollableArea.scrollBy({
						left: -scrollSize,
						behavior: "smooth",
					})
				});

				scrollRightButton.addEventListener('click', function () {
					const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
					scrollableArea.scrollBy({
						left: scrollSize,
						behavior: "smooth",
					})
				});
			})

			let scrollableArea = document.querySelector('div.ant-table-body');
			if (scrollableArea) {
				const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
				scrollableArea.scrollBy({
					left: -scrollSize,
					behavior: "smooth",
				})
			}
			this.resizeObserver = new ResizeObserver(() => {
				window.requestAnimationFrame(() => {
					this.updateScrollVisibility()
				})
			});
			if (scrollableArea) {
				this.resizeObserver.observe(scrollableArea);
			}

			// Remove the previous event listener, if any, to prevent multiple bindings
			window.removeEventListener('resize', this.updateScrollVisibility);

			// Attach the updated event listener
			window.addEventListener('resize', this.updateScrollVisibility)
		}
	},
	async created() {
		this.loading = true;
		this.$store.commit("UPDATE_CRUMBS", "commissionAllocationHistory");

		await this.FETCH_REALTORS();

		if (this.instance.productType === 'lowrise') {
			this.typeTab = 'lots'
			await this.getLotsHistory();
		} else {
			const promiseArray = [this.getUnitsHistory(), this.getAddOnsHistory()]
			await Promise.all(promiseArray)
		}

		this.loading = false;
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.updateScrollVisibility)
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	},
};
</script>

<style></style>
